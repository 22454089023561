import React from "react";



export default function SecurityPage(props){
    return(
        <div style={{margin:"50px"}}>
            <h1>Sawatch Labs Security Page</h1>
            <p>Updated 01/02/2024</p>

            <p>Security commitments include, but are not limited to, the following:</p> 
            <ul>
                <li>System features and configuration settings designed to authorize user access while restricting unauthorized users from accessing information not needed for their role</li>
                <li>Use of intrusion detection systems to prevent and identify potential security attacks from users outside the boundaries of the system</li>
                <li>Regular vulnerability scans over the system and network, and penetration tests over the production environment</li>
                <li>Operational procedures for managing security incidents and breaches, including notification procedures</li>
                <li>Use of encryption technologies to protect customer data both at rest and in transit</li>
                <li>Use of data retention and data disposal</li>
                <li>Up time availability of production systems</li>
            </ul>
            <p>Specific control activities that the service organization has implemented in this area are described below:</p>
            <ul>
                <li>Formally, documented organizational policy statements and codes of conduct communicate entity values and behavioral standards to personnel. </li>
                <li>Policies and procedures require employees sign an acknowledgment form indicating they have been given access to the employee manual and understand their responsibility for adhering to the policies and procedures contained within the manual.</li>
                <li>A confidentiality statement agreeing not to disclose proprietary or confidential information, including client information, to unauthorized parties is a component of the employee handbook. </li>
                <li>Background checks are performed for employees as a component of the hiring process.</li>
            </ul>




        </div>
    )
}