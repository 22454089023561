import React from "react";
import Terms from "./Terms";


export default function TermsOfService(props){
    //const html = require('./tos.html')
    //dangerouslySetInnerHTML={{__html: {html}}}
    return(
        <div style={{margin:"50px"}}>
            <Terms/ >
        </div>
    )
}