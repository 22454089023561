import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {  BrowserRouter as Router, } from "react-router-dom";

const secrets = require("./settings");
const version = secrets.dashboardVersion.split(".");
const basename = `/`;

ReactDOM.render(
    <Router basename={basename}>
        <App version={version} basename={basename}/>
    </Router>, document.getElementById('root'));

