import React, { useEffect, useState } from "react";
import styled from 'styled-components/macro';

export const AppContainer = styled.div`
    border: 0.1rem solid rgba(200, 200, 200, 0.1);
    border-radius: 0.2rem;
    margin-left: 8rem;
    margin-right: 6rem;
    margin-top: 3rem;
    padding: 2rem;
`;

export const StatusInfoWrapper = styled.div`
    border: 0.1rem solid rgba(200, 200, 200, .2);
    padding: ${(props) => props.padding ? props.padding : "1rem"};
    margin: 0.8rem;
    display: flex;
    flex-direction: row;
    width: 95%;
`;

export const StatusMarker = styled.div`
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "transparent"};
    width: ${(props) => props.width ? props.width : "1.8rem"};
    height: ${(props) => props.height ? props.height : "1.8rem"};
    border-radius: ${(props) => props.borderRadius ? props.borderRadius : "0.9rem"};
`;
export const StatusHeaderText = styled.div`
    color: "black";
    padding: 0.2rem;
    text-align: center;
    margin-left: ${(props) => props.marginLeft ? props.marginLeft : "2rem"};
    font-size: 1.3rem;
`;

export const StatusText = styled.div`
    color: "black";
    padding: 0.2rem;
    margin-left: 1rem;
    font-size: 1.3rem;
`;
export const StatusTextTimestamp = styled.div`
    color: "black";
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
`;

export const StatusTextWrapper = styled.div`
    width: 33%;
`;

export default function ApplicationStatus(props){

    //marker colors
    const UNCHECKED_COLOR = "rgba(50,50,50,.75)";
    const ACTIVE_COLOR = "rgba(0,155,50,0.85)";
    const WARNING_COLOR = "rgba(250,50,50,0.75)";

    const UPDATE_CADENCE = 10000; //refresh cadence in millis

    const ACTIVE_THRESHOLD = 5 * 60 * 1000; //time in miles past which site is inactive

    const [websiteUpdated, setWebsiteUpdated] = useState(new Date());
    const [websiteResponse, setWebsiteResponse] = useState(null);
    const [websiteStatusColor, setWebsiteStatusColor] = useState(UNCHECKED_COLOR);
    const [dashboardUpdated, setDashboardUpdated] = useState(new Date());
    const [dashboardResponse, setDashboardResponse] = useState(null);
    const [dashboardStatusColor, setDashboardStatusColor] = useState(UNCHECKED_COLOR);
    const [weatherUpdated, setWeatherUpdated] = useState(new Date());
    const [weatherResponse, setWeatherResponse] = useState(null);
    const [weatherStatusColor, setWeatherStatusColor] = useState(UNCHECKED_COLOR);
    const [vehiclesUpdated, setVehiclesUpdated] = useState(new Date());
    const [vehiclesResponse, setVehiclesRespones] = useState(null);
    const [vehiclesStatusColor, setVehiclesStatusColor] = useState(UNCHECKED_COLOR);
    const [apiUpdated, setApiUpdated] = useState(new Date());
    const [apiResponse, setApiResponse] = useState(null);
    const [apiResponseColor, setApiStatusColor] = useState(UNCHECKED_COLOR);
    const [now, setNow] = useState(new Date());

    const status = (response, ts) => {
        if(response && Date.now() - ts.getTime() < ACTIVE_THRESHOLD){
            return "Active";
        }
        return "InActive";
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
          }, UPDATE_CADENCE);
          return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if(websiteResponse)setWebsiteStatusColor(ACTIVE_COLOR);
        else setWebsiteStatusColor(WARNING_COLOR);
    }, [websiteResponse]);

    useEffect(() => {
        if(dashboardResponse)setDashboardStatusColor(ACTIVE_COLOR);
        else setDashboardStatusColor(WARNING_COLOR);
    }, [dashboardResponse]);

    useEffect(() => {
        if(apiResponse)setApiStatusColor(ACTIVE_COLOR);
        else setApiStatusColor(WARNING_COLOR);
    }, [apiResponse]);

    useEffect(() => {
        if(weatherResponse)setWeatherStatusColor(ACTIVE_COLOR);
        else setWeatherStatusColor(WARNING_COLOR);
    }, [weatherResponse]);

    useEffect(() => {
        if(vehiclesResponse)setVehiclesStatusColor(ACTIVE_COLOR);
        else setVehiclesStatusColor(WARNING_COLOR);
    }, [vehiclesResponse]);


    useEffect(() => {
        fetch("https://sawatchlabs.com", {mode: 'no-cors'})
            .then((data) => {
                if(data)setWebsiteResponse(data);
                else setWebsiteResponse(null);
                setWebsiteUpdated(new Date());
            })
    }, [now]);

    useEffect(() => {
        fetch("https://dashboard.sawatchlabs.com/authenticate", {mode: 'no-cors'})
            .then((data) => {
                if(data)setDashboardResponse(data);
                else setDashboardResponse(null);
                setDashboardUpdated(new Date());
            })
    }, [now]);

    useEffect(() => {
        fetch("https://api.sawatchlabs.com/milagedataforpractical")
            .then(data => data.json())
            .then((data) => {
                if(data.data)setApiResponse(data.data);
                else setApiResponse(null);
                setApiUpdated(new Date());
            });
    }, [now]);

    useEffect(() => {
        fetch("https://weather.sawatchlabs.com/getWeatherRead?ts=2023-10-17T12:00:00&lat=39.7670832&lon=-104.9973032")
            .then(data => data.json())
            .then((data) => {
                if(data.data && data.data[0])setWeatherResponse(data.data[0]);
                else setWeatherResponse(null);
                setWeatherUpdated(new Date());
            });
    }, [now]);

    useEffect(() => {
        fetch("https://vehicles.sawatchlabs.com/getVehicles?year=2000&make=jeep&model=cherokee")
            .then(data => data.json())
            .then((data) => {
                if(data.data)setVehiclesRespones(data.data);
                else setVehiclesRespones(null);
                setVehiclesUpdated(new Date());
            });
    }, [now]);

    return(
        <AppContainer>
            <StatusInfoWrapper>
                <StatusTextWrapper><StatusHeaderText marginLeft={"0rem"}>Application Name</StatusHeaderText></StatusTextWrapper>
                <StatusTextWrapper><StatusHeaderText marginLeft={"4.5rem"}>Status</StatusHeaderText></StatusTextWrapper>
                <StatusTextWrapper><StatusHeaderText marginLeft={"1rem"}>Status Updated</StatusHeaderText></StatusTextWrapper>
            </StatusInfoWrapper>
            <StatusInfoWrapper>
                <StatusMarker backgroundColor={websiteStatusColor}/>
                <StatusTextWrapper><StatusText>Sawatch Website</StatusText></StatusTextWrapper>
                <StatusTextWrapper><StatusHeaderText>{status(websiteResponse, websiteUpdated)}</StatusHeaderText></StatusTextWrapper>
                <StatusTextWrapper><StatusTextTimestamp>{websiteUpdated.toLocaleString()}</StatusTextTimestamp></StatusTextWrapper>
            </StatusInfoWrapper>
            <StatusInfoWrapper>
                <StatusMarker backgroundColor={dashboardStatusColor}/>
                <StatusTextWrapper><StatusText>Sawatch Analytics Dashboard</StatusText></StatusTextWrapper>
                <StatusTextWrapper><StatusHeaderText>{status(dashboardResponse, dashboardUpdated)}</StatusHeaderText></StatusTextWrapper>
                <StatusTextWrapper><StatusTextTimestamp>{dashboardUpdated.toLocaleString()}</StatusTextTimestamp></StatusTextWrapper>
            </StatusInfoWrapper>
            <StatusInfoWrapper>
                <StatusMarker backgroundColor={apiResponseColor}/> 
                <StatusTextWrapper><StatusText>Sawatch Data & Analytics API</StatusText></StatusTextWrapper>
                <StatusTextWrapper><StatusHeaderText>{status(apiResponse, apiUpdated)}</StatusHeaderText></StatusTextWrapper>
                <StatusTextWrapper><StatusTextTimestamp>{apiUpdated.toLocaleString()}</StatusTextTimestamp></StatusTextWrapper> 
            </StatusInfoWrapper>
            <StatusInfoWrapper>
                <StatusMarker backgroundColor={vehiclesStatusColor}/>   
                <StatusTextWrapper><StatusText>Sawatch Vehicles API</StatusText></StatusTextWrapper>
                <StatusTextWrapper><StatusHeaderText>{status(vehiclesResponse, vehiclesUpdated)}</StatusHeaderText></StatusTextWrapper>
                <StatusTextWrapper><StatusTextTimestamp>{vehiclesUpdated.toLocaleString()}</StatusTextTimestamp></StatusTextWrapper>
            </StatusInfoWrapper>
            <StatusInfoWrapper>
                <StatusMarker backgroundColor={weatherStatusColor}/>   
                <StatusTextWrapper><StatusText>Sawatch Weather API</StatusText></StatusTextWrapper>
                <StatusTextWrapper><StatusHeaderText>{status(weatherResponse, weatherUpdated)}</StatusHeaderText></StatusTextWrapper>
                <StatusTextWrapper><StatusTextTimestamp>{weatherUpdated.toLocaleString()}</StatusTextTimestamp></StatusTextWrapper>
            </StatusInfoWrapper>
        </AppContainer>
    )
}