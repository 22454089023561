import { SawatchLogo } from "@sawatchlabs/swt-components";
import { Switch, Route } from "react-router-dom";
import ApplicationStatus from "./components/application-status-components/swt-application-status";
import TermsOfService from "./components/application-status-components/TermsOfService";
import PrivacyPolicyPage from "./components/application-status-components/PrivacyPolicyPage";
import SecurityPage from "./components/application-status-components/SecurityPage";

function App() {
  return (
    <div>
      <SawatchLogo />
      <Switch>
        <Route exact path="/">
          <ApplicationStatus />
        </Route>
        <Route path="/tos">
          <TermsOfService />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicyPage />
        </Route>
        <Route path="/security">
          <SecurityPage />
        </Route>
      </Switch>

    </div>
  );
}

export default App;