import React from "react";
import PrivacyTerms from "./PrivacyTerms";


export default function PrivacyPolicyPage(props){
    //const html = require('./tos.html')
    //dangerouslySetInnerHTML={{__html: {html}}}
    return(
        <div style={{margin:"50px"}}>
            <PrivacyTerms />
        </div>
    )
}